import Axios from '@/axios'

// 新增修改菜单数据
export const save = function (cond={}) {
  return Axios.post('/admin/menu/save', cond);
}

// 获取菜单列表
export const findList = function (cond={}) {
  return Axios.post('/admin/menu/findList', cond);
}

// 根据菜单id删除相关菜单
export const del = function (cond={}) {
  return Axios.post('/admin/menu/delete', cond);
}

export const queryMenuByRoleId = function (roleId) {
  return Axios.get('/admin/menu/query/' + roleId);
}


export const queryMenu = function (cond={}) {
  return Axios.get('/admin/menu/queryMenu',cond);
}

export const menuDragSort = function (cond={}) {
    return Axios.post('/admin/menu/menuDragSort',cond);
}
