import Axios from '@/axios'

export const findByPage = function (cond={}) {
  return Axios.post('/admin/dbBackUp/findByPage', cond);
}
export const backUp = function (cond={}) {
    return Axios.post('/admin/dbBackUp/backUp', cond);
}
export const del = function (cond={}) {
    return Axios.post('/admin/dbBackUp/delete', cond);
}
