import ElementUI from 'element-ui';
ElementUI.TableColumn.props.showOverflowTooltip = {
    type: Boolean,
    default: false
};

ElementUI.Pagination.props.layout.default = "total, prev, pager, next , jumper,sizes";
ElementUI.Pagination.props.pageSizes.default = ()=>{return [50,100,200,500]};
ElementUI.InputNumber.props.controlsPosition.default = 'right'

const Message = ElementUI.Message;
ElementUI.Message = function (options) {
    const duration = {
        success:3000,
        info:3000,
        warning:3000,
        error:0,
    }
    const opt = {
        duration: duration[options.type],
        showClose:true,
    }
    return Message(opt.combination(options));
}
ElementUI.Message.info = function (message) {
    return Message.info({
        message: message,
        duration: 3000,
        showClose:true,
    })
}
ElementUI.Message.warning = function (message) {
    return Message.warning({
        message: message,
        duration: 3000,
        showClose:true,
    })
}
ElementUI.Message.error = function (message) {
    return Message.error({
        message: message,
        duration: 0,
        showClose:true,
    })
}
ElementUI.Message.success = function (message) {
    return Message.success({
        message: message,
        duration: 3000,
        showClose:true,
    })
}
