import axios from "axios";
import store from '@/store/index.js'
import Router from "@/router/index.js";
import {SERVICEADDRESS} from '@/config'


import {Message} from "element-ui";

axios.defaults.timeout = 0;
//axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
//axios.defaults.withCredentials = true;

// Http's request 拦截器
axios.interceptors.request.use(
    config => {

        config.baseURL = SERVICEADDRESS[process.env.NODE_ENV][config.url.split('/')[1]];
        config.url = config.url.replace(`/${config.url.split('/')[1]}`,'')
        if (store.state.user.userInfo.token) {
            try {
                delete config.data.createDate;
                delete config.data.modifyDate;
            }catch (e) {

            };

            if(config.url.split('/')[1]!='admin'){

                if(Object.prototype.toString.call(config.data) == '[object Object]'){
                    config.data.personId = store.state.user.userInfo.id;
                }else if(Object.prototype.toString.call(config.data) == '[object FormData]'){
                    config.data.append('personId', store.state.user.userInfo.id);
                }

            }

            config.headers['token'] = store.state.user.userInfo.token;
        } else {
            Router.push('/login');
        };
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    data => {
        // 二进制数据则直接返回
        if (data.request.responseType ===  'blob' || data.request.responseType ===  'arraybuffer') {
            return Promise.resolve(data.data);
        }

        if (data.status != 200) {
            return Promise.reject(err);
            Message.error(data.status);

        } else {
            if (data.data.code == 200) {
                if(data.data.token){
                    var userInfo = store.state.user.userInfo;
                    userInfo.token = data.data.token;
                    store.commit({
                        type: 'user/SET_USER_INFO',
                        userInfo:userInfo,
                    });
                }
                return Promise.resolve(data.data);
            } else if (data.data.code == 509) {
                Message.error('登录超时，请重新登录');
                Router.push('/login');
                return Promise.reject(data);
            } else {
                Message.error(data.data.message||'请求错误');
                return Promise.reject(data);
            }
        }
    },
    err => {
        var msg = '请求错误';
        try {
            msg = err.response.data.msg;
            if(err.response.data.code == 509){
                Message.error('登录超时，请重新登录');
                Router.push('/login');
                return Promise.reject(err);
            }
        }catch (e) {

        };
        Message.error(msg);
        return Promise.reject(err);
    }
);
export default axios;
