//将平铺数据转化为树数据
export const initTreeData = function (s,pid='parentId',id='id') {
  let dataArr = [];

  function getChildren(activeId) {
    let arr = [];
    for (let i = 0; i < s.length; i++) {
      if (s[i][pid] == activeId) {
        let obj = s[i]//.deepClone();
        if (getChildren(obj[id])) {
          obj.children = getChildren(obj[id])
        }
        arr.push(obj);
      }
    }
    if (arr.length > 0) {
      return arr;
    } else {
      return null;
    }
  }
  for (let i = 0; i < s.length; i++) {
    if (s[i][pid] == 0) {

      let obj = s[i]//.deepClone();
      if (getChildren(obj[id])) {
        obj.children = getChildren(obj[id])
      }

      dataArr.push(obj);
    }
  }
  return dataArr;
};


//将树数据转化为平铺数据
export const treeToTile = function (treeData, childKey = 'children') {
    const arr = []
    const expanded = data => {
        if (data && data.length > 0) {
            data.filter(d => d).forEach(e => {
                arr.push(e)
                expanded(e[childKey] || [])
            })
        }
    }
    expanded(treeData)
    return arr
}

//函数节流 防抖
export const throttle = (function(){
  var timer;
  var timeHistory = new Date().getTime();
  return function(callback,time=1000){
    if(timer) clearTimeout(timer);

    var t = new Date().getTime() - timeHistory;

    if(t >time){
      timeHistory = new Date().getTime();
      callback();
    }else {
      timer = setTimeout(function(){
        timeHistory = new Date().getTime();
        callback();
      },time);
    };
  };
})();


//生成uuid
export const getUUID = function () {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};


export const printer = function (html) {
  var iframe = document.getElementById("print-iframe");
  if (!iframe) {
    iframe = document.createElement('IFRAME');
    iframe.setAttribute("id", "print-iframe");
    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:0;top:0;');
    document.body.appendChild(iframe);
    var style = '<style>';
    style += '@page{size: A4;margin-top: 0;margin-bottom: 0;}';
    style += '.h1{text-align: center;padding:30px 0 0 0;}';
    style += '.container { width: 1000px;display: flex;flex-direction: column;justify-content: center;align-items: center;margin: auto;}';
    style += '.details { font-size: 20px;text-indent: 2em;}';
    style += '.table {margin-top: 15px;}';
    style += 'td {height: 68px;text-align: center;border: 1px solid #000;border-collapse: collapse;}';
    style += 'table {width: 1000px;text-align: center;border: 1px solid #000;border-collapse: collapse;margin-bottom: 30px;}';
    style += 'table:last-child {margin-bottom: 0;}';
    style += 'table td {padding: 10px;}';
    style += '.table_situation {padding:20px  10px;font-size: 20px;text-align: left;text-indent: 2em;}';
    style += '</style>';
    iframe.contentWindow.document.write(style)
    iframe.contentWindow.document.write('<body></body>')
  }
  var doc = iframe.contentWindow.document;
  doc.body.innerHTML = html;
  doc.close();
  iframe.contentWindow.focus();
  iframe.contentWindow.print();
};

export const download = function (blob,name) {
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
};

export const fileParsing = function (file,coding='utf-8') {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function(){
            resolve(this.result)
        };
        reader.onerror = function(){
            reject(this.error);
        };
        reader.readAsText(file,coding);
    });
};

export const layerDataConversion = function (res,separate='.') {
    var data = [];
    var bs = getUUID();

    function getChildren(obj,arr){
        for(let k in res){
            let arr = k.split(separate);
            let storey = obj.storey+1;
            let reg = new RegExp(`^${obj.keyIntercept}`);
            if(reg.test(k)){
                if(arr.length>storey+2){
                    if(!data.some(r=>{return r.keyIntercept==`${obj.keyIntercept}${arr[storey]}${separate}`})){
                        let o = {
                            storey,
                            id:getUUID(),
                            parentId:obj.id,
                            keyIntercept:`${obj.keyIntercept}${arr[storey]}${separate}`,
                            name:`${arr[storey]}`,
                            isLeaf:false,
                            data:{},
                        }
                        getChildren(o,arr)
                        data.push(o);
                    }
                }else {
                    if(data.some(r=>{return r.keyIntercept==`${obj.keyIntercept}${arr[storey]}`})){
                        data.some(r=>{
                            if(r.keyIntercept==`${obj.keyIntercept}${arr[storey]}`){
                                r.data[arr[arr.length-1]] = res[k];
                            }
                        })
                    }else {
                        let o = {
                            storey,
                            id:getUUID(),
                            parentId:obj.id,
                            keyIntercept:`${obj.keyIntercept}${arr[storey]}`,
                            name:`${arr[storey]}`,
                            isLeaf:true,
                            data:{},
                        }
                        o.data[arr[arr.length-1]] = res[k];
                        data.push(o)
                    }
                }
            }
        }
    }
    for(let k in res){
        let arr = k.split(separate);
        if(arr.length>1){
            if(!data.some(r=>{return r.keyIntercept==`${arr[0]}${separate}`})){
                let o = {
                    storey:0,
                    id:getUUID(),
                    parentId:0,
                    keyIntercept:`${arr[0]}${separate}`,
                    name:`${arr[0]}`,
                    isLeaf:false,
                    data:{},
                }
                getChildren(o,arr)
                data.push(o);
            }
        }else {
            if(data.some(r=>{return r.keyIntercept==bs})){
                data.some(r=>{
                    if(r.keyIntercept==bs){
                        r.data[k] = res[k];
                    }
                })
            }else {
                let o = {
                    storey:0,
                    id:getUUID(),
                    parentId:0,
                    keyIntercept:bs,
                    name:'',
                    isLeaf:true,
                    data:{},
                }
                o.data[k] = res[k];
                data.push(o)
            }
        }
    }
    return initTreeData(data);
};

