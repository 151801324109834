import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findPage = function (cond={}) {
  return Axios.post('/admin/userOnline/findPage', cond);
}
export const onlineUserStatistics = function (cond={}) {
    return Axios.post('/admin/userOnline/onlineUserStatistics', cond);
}
export const current = function (cond={}) {
    return Axios.post('/admin/userOnline/current', cond);
}
