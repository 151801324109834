import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findPage = function (cond={}) {
  return Axios.post('/admin/dept/findPage', cond);
}

export const findByList = function (cond={}) {
  return Axios.post('/admin/dept/findByList', cond);
}


export const save = function (cond={}) {
  return Axios.post('/admin/dept/save', cond);
}


export const del = function (cond={}) {
  return Axios.post('/admin/dept/delete', cond);
}
