import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from '@/axios'
import store from '@/store'
import { initTreeData } from '@/util/common'
import api from '@/api/index'
import {Message} from "element-ui";
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login', // 登录页面
        name: 'Login',
        meta: {name:'登录',verificationLogin:true},
        component: () => import(/* webpackChunkName: "login" */'@/views/Login.vue'),
    }
];

const router = new VueRouter({
  // mode: 'history',
  //base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

    if (to.meta.verificationLogin) {
      next();
    } else {

      if(store.state.user.userInfo.token){
        if (store.state.user.loadMenus) { // 判断是否已拉取过菜单防止死循环
          next();
        } else {
          loadMenus(next, to);
        };
      }else {
       next(`/login?redirect=${to.path}`);
      };
    }

});


const loadMenus = (next, to) => {
  router.matcher = new VueRouter({
    routes,
  }).matcher
  api['admin/menu'].queryMenu().then(res => {

    var menuArr = res.data;


    if (menuArr.length > 0) {


      var navMenu = initTreeData(menuArr);

      store.commit({
         type: 'user/SET_MENUS',
         menus:navMenu
      });

      var s = menuArr;
      var dataArr = [];

      function getChildren(id) {
        var arr = [];
        for (let i = 0; i < s.length; i++) {
          if (s[i].parentId == id) {
            let obj = {
              path: s[i].href||s[i].code,
              name: s[i].code,
              meta: s[i],
              component: loadView(s[i])
            }


            if (getChildren(s[i].id)) {
              obj.children = getChildren(s[i].id)
            }
            arr.push(obj);
          }
        }
        if (arr.length > 0) {
          return arr;
        } else {
          return null;
        }
      }

      for (let i = 0; i < menuArr.length; i++) {
        if (s[i].parentId == 0) {

          let obj = {
            path: s[i].href||s[i].code,
            name: s[i].code,
            meta: s[i],
            component: loadView(s[i])
          };
          if (getChildren(s[i].id)) {
            obj.children = getChildren(s[i].id)
          }

          dataArr.push(obj);
        }
      }

      var href = dataArr[0].linkTo ? dataArr[0].linkTo : dataArr[0].path;

      function recursion(data) {
        if (data.children && data.children.length > 0) {
          recursion(data.children[0]);
        } else {
          href = data.linkTo ? data.linkTo : data.path;

          dataArr.push({
            path: '*',
            redirect: href,
            hidden: true
          });

          store.dispatch('user/setLoadMenus', true).then(() => {
            router.addRoutes(dataArr);
            next({
              ...to,
              replace: true
            }); // hack方法确保addRoutes已完成
          }).catch(() => {

          });

        }
      }
      recursion(dataArr[0]);
    }else {
      Message.error('该用户没有分配权限！');
    };
  }).catch(err => {
    console.log(err)
  });

};

const loadView = (r) => {
  if (r.component) {
    //return (resolve) => require([`@/${view}.vue`], resolve)
    return () => import(/* webpackChunkName: "[request]" */`@/${r.component}.vue`);
  } else {
    if(r.formConfigurationTableName){

      return () => import(/* webpackChunkName: "[request]" */`@/components/dynamicForms/Index.vue`);
    }else {
      return {
        // render: c => c('router-view')
        render: c => c('keep-alive',[c('router-view')])
        // render: c => c('keep-alive',{},[c('router-view')])
      }
    }
  }
};

export default router
