
const tagsView = {
  namespaced: true,
  state: {
    data:[],
  },
  mutations: {
    SET_DATA: (state, payload) => {
      if (state.data.some(v => v.code === payload.data.code)) return
      state.data.push(payload.data)
    },
    DEL_DATA: (state, payload) => {
      console.log(payload)

      for(let i=0;i<state.data.length;i++){
        let path = state.data[i].linkTo || state.data[i].href;
        if(payload.path==path){
          state.data.splice(i,1);
        }


      }
    },
  },
  actions: {
    setData({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_DATA',{data});
        resolve();
      });
    },
    delData({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('DEL_DATA',{data});
        resolve();
      });
    },
  }
}

export default tagsView
