import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findPage = function (cond={}) {
  return Axios.post('/admin/formConfig/findPage', cond);
}

export const save = function (cond={}) {
  return Axios.post('/admin/formConfig/save', cond);
}

export const del = function (cond={}) {
  return Axios.post('/admin/formConfig/delete', cond);
}
export const deleteField = function (cond={}) {
  return Axios.post('/admin/formConfig/deleteField', cond);
}

export const getField = function (code,cache=false) {
  return new Promise((resolve,reject) => {
    if(cache&&store.state.dict.data[code]){
      resolve(store.state.dict.data[code])
    }else {
      Axios.post('/admin/formConfig/getField/'+code).then(res=>{
        store.commit({
          type: 'formConfig/SET_DATA',
          data:res,
          code,
        });
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }
  });
}
