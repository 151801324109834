import Axios from '@/axios'
import { Message } from "element-ui";
import store from '@/store'



export const save = function (cond={}) {
  return Axios.post('/admin/dict/save', cond);
};

export const findAllDictByCode = function (code,cache=false) {

  if(code){

    return new Promise((resolve,reject) => {

      if(cache&&store.state.dict.data[code]){
        resolve(store.state.dict.data[code])
      }else {
        Axios.post('/admin/dict/findAllDictByCode/'+code).then(res=>{
          store.commit({
            type: 'dict/SET_DATA',
            data:res,
            code,
          });
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      }
    });

  }else {
    Message.error('获取字典数据：type不能为空');
  };

};

export const del = function (cond={}) {
  return Axios.post('/admin/dict/delete', cond);
};

export const dictDragSort = function (cond={}) {
    return Axios.post('/admin/dict/dictDragSort',cond);
}
