import Axios from '@/axios'
import { Message } from "element-ui";


export const save = function (cond={}) {
  return Axios.post('/admin/dictClassify/save', cond);
};

export const findPage = function (cond={}) {

  return Axios.post('/admin/dictClassify/findPage',cond);
};

export const del = function (cond={}) {
  return Axios.post('/admin/dictClassify/delete', cond);
};
