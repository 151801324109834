import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './prototype.js'
import './vfilter.js'

import api from './api/index'
Vue.prototype.$api = api;

//自定义控件挂载
Vue.config.productionTip = false

import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

// import '@arcgis/core/assets/esri/themes/light/main.css';

Vue.use(ElementUI);
import './styles/icon/iconfont.css';


import './styles/font/iconfont.css';

import './styles/element-variables.scss';
import "./styles/common.scss"
import "./elementui.js"




if (process.env.NODE_ENV === 'development') {

  // require('../mock/index');//运行mock

}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
