import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findList = function (type) {
    return Axios.post(`/admin/layerField/findList/${type}`);
}
export const del = function (cond={}) {
    return Axios.post('/admin/layerField/delete', cond);
}
export const save = function (cond={}) {
    return Axios.post('/admin/layerField/save', cond);
}
