<template>
    <div id="app" class="app">
        <template v-if="verificationLogin">
            <router-view></router-view>
        </template>
        <template v-else>
            <Header class="header transition" />
            <div class="menu transition" v-if="$route.matched.length>1">
                <el-scrollbar class="menu_content">
                    <VerMenuEle :isCollapse="adaptation.isCollapse"></VerMenuEle>
                </el-scrollbar>
            </div>
            <div class="main transition">
                <TagsView class="tagsView" v-if="$route.matched.length>1" />
                <keep-alive>
                    <router-view class="view"></router-view>
                </keep-alive>
            </div>
        </template>
    </div>
</template>
<script>

export default {
    name: "App",
    components: {
        TagsView: () => import("@/views/common/TagsView.vue"),
        Header: () => import("@/views/common/Header.vue"),
        VerMenuEle: () => import("@/views/common/VerMenuEle.vue")
    },
    data() {
        return {
            verificationLogin: true,
            adaptation:{
                width:window.innerWidth,
                height:window.innerHeight,
            },
        };
    },
    provide () {
        return {
            adaptation:this.adaptation,
            formMunted(){},
        }
    },
    watch: {
        $route:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (to){
                this.verificationLogin = to.meta.verificationLogin
            }
        },
    },
    methods: {},
    mounted() {
        window.addEventListener('resize',e=> {
            this.adaptation.width = e.target.innerWidth;
            this.adaptation.height = e.target.innerHeight;
        });
        window.addEventListener('click',e=> {
            try{
                window.mousemove()
            }catch(err){
            }
        });
    },
};
</script>
<style lang='scss'>

    @import "@/styles/config.scss";
    .app {
        position: relative;
        top:0;
        left:0;
        height: 100%;
        overflow: hidden;
        background: #E9F5F5;
        //@media screen and (max-height: 680px) {
        //    height: 680px;
        //}
        //@media screen and (max-width: 1200px) {
        //    width: 1200px;
        //}
        //@media only screen and (min-aspect-ratio: 1920/1080) {
        //    @media screen and (max-height: 680px) {
        //        height: 680px;
        //    }
        //}
        //@media only screen and (max-aspect-ratio: 1920/1080) {
        //    @media screen and (max-width: 930px) {
        //        width: 930px;
        //    }
        //}
        .transition {
            transition:.1s cubic-bezier(.92,1.1,.71,.94);
        }
        >.header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 56px;
            background:$themeColor;
            background: linear-gradient(135deg, #00C5E7 0%, #00C1CD 51%, #12C299 100%);
            z-index:999;
            color:#fff;
            /*border-bottom:$themeColor solid 1px;*/
        }
        >.menu {
            position: absolute;
            top: 56px;
            left: 0;
            bottom: 0;
            width: 256px;
            background: #fff;
            border-right:#ddd solid 1px;
            padding:0 0 0 10px;
            overflow:hidden;
            .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .menu_content {
                height:100%;
                padding-top:10px;
            }
            +.main {
                left: 256px;
            }
        }
        >.main {
            position: absolute;
            top: 56px;
            left: 0;
            right: 0;
            bottom: 0;
            .tagsView {
                +.view {
                    top:51px;
                }
            }
            >.view {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                overflow: auto;
            }
        }
    }
    .el-menu--vertical {
        .el-menu--popup {
            /*background:rgba(1, 77, 103, 1)!important;*/

            .iconfont {
                font-size:16px;
                margin-right:5px;
                color:#fff;
            }
            i {
                color: #f2f3f5;
            }
            .el-menu-item {
                &.is-active {
                    color:#fff;
                    background: #001e28 !important;
                }
            }
        }
    }

</style>
