export const SERVICEADDRESS = {
    development:{

        admin:'http://192.168.137.136:8999/admin',
        api:'http://192.168.137.136:8999/api',
        annex:'http://192.168.137.136:8999/upload',

        // admin:'http://huitukj.com:9027/admin',
        // api:'http://huitukj.com:9027/api',
        // annex:'http://huitukj.com:9027/upload',

    },
    production:{
        // admin:'http://huitukj.com:9027/admin',
        // api:'http://huitukj.com:9027/api',
        // annex:'http://huitukj.com:9027/upload',
        admin:'/admin',
        api: '/api',
        annex: '/annex',
    },
}
