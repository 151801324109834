import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findPage = function (cond={}) {
  return Axios.post('/admin/dynamicForms/findPage', cond);
}

export const save = function (cond={}) {
  return Axios.post('/admin/dynamicForms/save', cond);
}

export const del = function (cond={}) {
  return Axios.post('/admin/dynamicForms/delete', cond);
}

